import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404 - Uytterhaegen Jan BV" pathname="/" />
      <div className="main-hero notFound">
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h1>404</h1>
            <h3>Pagina niet gevonden.</h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="wrapper">
          <div className="content"></div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
